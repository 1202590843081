import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';

import { TokenService } from '@core/services/auth/token.service';
import { RoleService } from '@core/services/role.service';

export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot): boolean | UrlTree => {
  const router: Router = inject(Router);
  const tokenService: TokenService = inject(TokenService);
  const roleService: RoleService = inject(RoleService);

  if (tokenService.tokenValid()) {
    const role: string = roleService.organizationRole();
    const { roles } = route.data;

    if (!roles || (roles && roles.includes(role))) {
      return true;
    }
  }

  return router.createUrlTree(['/']);
};
